import React from 'react';

const Footer = () => {
  return (
    <footer className="w-full bg-gradient-to-tr from-blue-200 to-blue-100  text-gray-800 text-center py-2 mt-8 rounded-t-xl text-sm">
      <p>© {new Date().getFullYear()} Recurrent Software Private Limited. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
