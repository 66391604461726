import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaUserAlt, FaLock } from 'react-icons/fa';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai'
import Modal from '../components/Modal';



const ResetPasswordPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const username = searchParams.get('username');
  const token = searchParams.get('token');
  const [password, setPassword] = useState("");
  const [isTouched, setIsTouched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTokenValid, setTokenValid] = useState(false);

  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_BASE_URL;
 
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent default form submission behavior
    if (!validatePassword()) {
      toast.error("Password does not meet requirements.");
      return;
    }

    fetch(`${baseURL}/reset`, { // Replace 'SET_PASSWORD_API' with your actual API endpoint
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, password, token })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to set password');
        }
        return response.json();
      })
      .then(data => {
        toast.success("Password set successfully!");
        navigate('/login'); // Navigate to login page on success
      })
      .catch(error => {
        toast.error("Error setting password: " + error.message);
      });
  };

  useEffect(() => {
    if (!token) {
      toast.error("No token provided.");
      return;
    }
    
    setIsLoading(true)
    fetch(`${baseURL}/stores/validate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, token })
    })
    .then(response => response.json())
    .then(data => {
  
        setIsLoading(false)
        setTokenValid(true);
    })
    .catch(error => {
      toast.error("Error validating token");
    });
  }, []);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    if (!isTouched) setIsTouched(true);
  };

  const validatePassword = () => {
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password);
    const isLongEnough = password.length >= 8;
    return hasNumber && hasSpecialChar && isLongEnough;
  };



  if (!username || !isTokenValid) {
    return <div className='text-3xl text-center p-20'>Invalid access. Token is not active anymore.</div>;
  }


  return (<div className="min-h-screen flex flex-col" style={{
    backgroundImage: 'url(/hero.webp)',
    backgroundSize: 'cover',
    backgroundPosition: 'left',
    backgroundAttachment: 'fixed'
  }}>
    <ToastContainer position='bottom-right' />
    <div className="h-screen bg-gray-400 bg-opacity-80 flex justify-center items-center">
      <div className="flex flex-col gap-4 items-center h-auto w-full max-w-md px-4 bg-brand-blue-light shadow-lg p-6 rounded-2xl">
        <div className="mb-4 text-white text-3xl">Set Password</div>
        <form onSubmit={handleSubmit} className="rounded flex flex-col justify-center items-center w-full">
          {/* Username Input */}
          <div className="mb-4 w-full">
            <label htmlFor="username" className="block text-sm font-medium text-white flex items-center">
              <FaUserAlt className="mr-2" />Username
            </label>
            <input
              id="username"
              type="text"
              value={username}
              disabled
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none"
            />
          </div>
          {/* Password Input */}
          <div className="mb-4 w-full relative">
            <label htmlFor="password" className="block text-sm font-medium text-white flex items-center">
              <FaLock className="mr-2" />Password
            </label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none"
            />
            {/* Password Validation Icons */}
            {isTouched && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                {validatePassword() ? (
                  <AiOutlineCheckCircle className="text-green-500 text-xl" />
                ) : (
                  <AiOutlineCloseCircle className="text-red-500 text-xl" />
                )}
              </div>
            )}
            {isTouched && !validatePassword() && (
              <p className="text-white text-xs mt-2 text-center">Password must be at least 8 characters long and include a number and a special character.</p>
            )}
          </div>
         
          {/* Submit Button */}
          <button type="submit" disabled={!validatePassword()}
            className={`mt-5 w-full md:w-3/4 bg-[#8A6088] text-white font-bold py-2 px-4 rounded-full shadow-lg 
                 ${!validatePassword() ? 'cursor-not-allowed bg-gray-500' : 'cursor-pointer hover:bg-[#7A5078]'}`}>
            Submit
          </button>
        </form>
      </div>
      
    </div>
  </div>);
};

export default ResetPasswordPage;
