import React, { useState } from 'react';
import { FaAngleDown, FaAngleRight, FaBullhorn, FaCamera, FaCameraRetro, FaCodeBranch, FaEthernet, FaLock, FaSave, FaTag, FaUser, FaUserLock } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';


interface RTSPConfiguratorProps {
    onClose: () => void; 
    onSuccess: () => void; 
  }

const RTSPConfigurator: React.FC<RTSPConfiguratorProps> = ({onClose, onSuccess}) => {
    const [openSection, setOpenSection] = useState<string | null>('getIP');
    const [ipAddress, setIpAddress] = useState('');
    const [cameraBrand, setCameraBrand] = useState('HikVision');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [channel, setChannel] = useState('');
    const [tag, setTag] = useState('');
    const [cameraStatus, setCameraStatus] = useState('UnKnown');

    const baseUrl=process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem('token');

    const toggleSection = (section: string) => {
        setOpenSection(openSection === section ? null : section);
    };

    const handleSaveConfiguration = () => {
        const escapedUsername = encodeURIComponent(username);
        const escapedPassword = encodeURIComponent(password);

        // Construct the RTSP URL with the escaped credentials
        const rtspUrl = `rtsp://${escapedUsername}:${escapedPassword}@${ipAddress}/Streaming/Channels/${channel}`;

        fetch(`${baseUrl}/camera`, { // Replace 'SET_PASSWORD_API' with your actual API endpoint
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`

            },
            body: JSON.stringify({ 'rtsp_url': rtspUrl, tag })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to set password');
                }
                return response.json();
            })
            .then(data => {
                toast.success("Camera added successfully!");
                onSuccess()
                onClose()
            })
            .catch(error => {
                toast.error("Error adding camera ");
                onClose()
            });
    }

    const handleCheckConnection = () => {
        const escapedUsername = encodeURIComponent(username);
        const escapedPassword = encodeURIComponent(password);

        // Construct the RTSP URL with the escaped credentials
        const rtspUrl = `rtsp://${escapedUsername}:${escapedPassword}@${ipAddress}/Streaming/Channels/${channel}`;

        fetch(`${baseUrl}/camera/connection`, { // Replace 'SET_PASSWORD_API' with your actual API endpoint
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ 'rtsp_url': rtspUrl })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to set password');
                }
                return response.json();
            })
            .then(data => {
                // toast.success("Password set successfully!");
                setCameraStatus('connected')
            })
            .catch(error => {
                // toast.error("Error setting password: " + error.message);
                setCameraStatus('error')
            });

        // Implement saving logic or API call to save the configuration
    };

    return (
        <div className="flex flex-col w-full ">
            <h2 className="text-3xl text-center mb-4">Add Camera</h2>
            <div className='bg-white rounded-lg mb-4'>
                <div onClick={() => toggleSection('getIP')} className="cursor-pointer flex items-center justify-between p-2">
                    <div className="flex justify-center items-center gap-8">
                        <FaCamera className='ml-4 text-4xl text-brand-blue-light' />
                        <label className="text-brand-blue-light text-xl flex flex-col items-start">
                            <span>Camera and network details</span>
                            <span className='text-gray-400 text-sm'>Camera brand and Network IP details</span>
                        </label>
                    </div>
                    <span className="mr-2 text-3xl text-brand-blue-light">{openSection === 'getIP' ? <FaAngleDown /> : <FaAngleRight />}</span>
                </div>
                {openSection === 'getIP' && (
                    <div className="flex flex-col gap-4 p-4 bg-white  ml-20">
                        <div className="flex items-center">
                            <label htmlFor="ipAddress" className="flex items-center text-lg font-medium text-gray-700">
                                <FaEthernet className="mr-2 text-brand-blue-light" />
                                Static IP Address
                            </label>
                            <input
                                id="ipAddress"
                                type="text"
                                value={ipAddress}
                                onChange={(e) => setIpAddress(e.target.value)}
                                className="ml-4 p-2 border border-gray-300 rounded focus:outline-none "
                                placeholder="Enter IP Address"
                            />
                        </div>

                        <div className="flex items-center">
                            <label htmlFor="cameraBrand" className="flex items-center text-lg font-medium text-gray-700">
                                <FaBullhorn className="mr-2 text-brand-blue-light" />
                                Camera Brand
                            </label>
                            <select
                                id="cameraBrand"
                                value={cameraBrand}
                                onChange={(e) => setCameraBrand(e.target.value)}
                                className="ml-8 p-2 border border-gray-300 rounded focus:outline-none focus:ring-brand-blue-light transition duration-150 ease-in-out"
                            >
                                <option value="HikVision">HikVision</option>
                                {/* Additional brands can be added here as needed */}
                            </select>
                        </div>
                        <div className='flex flex-row-reverse'>
                            <button onClick={() => setOpenSection('getCredentials')} className="mr-6 bg-brand-blue-light p-2 w-32 text-white rounded-full">Next</button>
                        </div>

                    </div>
                )}
                <hr className="my-4 border-gray-300" />
                <div onClick={() => toggleSection('getCredentials')} className="cursor-pointer flex items-center justify-between p-2">
                    <div className="flex justify-center items-center gap-8">
                        <FaUserLock className='ml-4 text-4xl text-brand-blue-light' />
                        <label className="text-brand-blue-light text-xl flex flex-col items-start">
                            <span>Credenitals</span>
                            <span className='text-gray-400 text-sm'>Credentials to access Camera Streams</span>
                        </label>
                    </div>
                    <span className="mr-2 text-3xl text-brand-blue-light">{openSection === 'getIP' ? <FaAngleDown /> : <FaAngleRight />}</span>
                </div>
                {openSection === 'getCredentials' && (
                    <div className="flex flex-col gap-4 p-4 bg-white  ml-20">
                        <div className="flex items-center">
                            <label htmlFor="ipAddress" className="flex items-center text-lg font-medium text-gray-700">
                                <FaUser className="mr-2 text-brand-blue-light" />
                                Username
                            </label>
                            <input type="text" value={username} onChange={(e) => setUsername(e.target.value)}
                                className="ml-4 p-2 w-64 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-brand-blue-light transition duration-150 ease-in-out"
                                placeholder="Enter Username for RTSP stream" />


                        </div>

                        <div className="flex items-center">
                            <label htmlFor="cameraBrand" className="flex items-center text-lg font-medium text-gray-700">
                                <FaLock className="mr-2 text-brand-blue-light" />
                                Password
                            </label>
                            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)}
                                className="ml-5 w-64 p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-brand-blue-light transition duration-150 ease-in-out"
                                placeholder="Enter Password for RTSP stream" />

                        </div>
                        <div className='flex flex-row-reverse'>
                            <button onClick={() => setOpenSection('confirmConnection')} className="mr-6 bg-brand-blue-light p-2 w-32 text-white rounded-full">Next</button>
                        </div>

                    </div>
                )}


                <hr className="my-4 border-gray-300" />
                <div onClick={() => toggleSection('confirmConnection')} className="cursor-pointer flex items-center justify-between p-2">
                    <div className="flex justify-center items-center gap-8">
                        <FaSave className='ml-4 text-4xl text-brand-blue-light' />
                        <label className="text-brand-blue-light text-xl flex flex-col items-start">
                            <span>Preview and Submission</span>
                            <span className='text-gray-400 text-sm'>Check the stream connection and save</span>
                        </label>
                    </div>
                    <span className="mr-2 text-3xl text-brand-blue-light">{openSection === 'getIP' ? <FaAngleDown /> : <FaAngleRight />}</span>
                </div>
                {openSection === 'confirmConnection' && (
                    <div className="p-4 ml-20 flex flex-col gap-4">
                        {/* Channel Input */}
                        <div className="flex items-center">
                            <label htmlFor="channel" className="flex items-center text-lg font-medium text-gray-700 w-48">
                                <FaCameraRetro className="mr-2 text-brand-blue-light" />
                                Channel
                            </label>
                            <input
                                id="channel"
                                type="text"
                                value={channel}
                                onChange={(e) => setChannel(e.target.value)}
                                className="flex-grow p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-brand-blue-light transition duration-150 ease-in-out"
                                placeholder="Enter channel number" />
                        </div>

                        {/* Connection Status */}
                        <div className="flex items-center gap-4">
                            <label className="w-48">Connection status</label>
                            <div className={`py-1 px-4 text-white rounded-full w-fit text-sm ${cameraStatus === 'connected' ? 'bg-green-500' :
                                    cameraStatus === 'error' ? 'bg-red-500' :
                                        cameraStatus === 'loading' ? 'bg-gray-500' : 'bg-gray-600'
                                }`}>
                                {cameraStatus}
                            </div>
                            <button onClick={handleCheckConnection} className="bg-brand-blue-light py-1 px-4 text-white rounded-full">
                                Verify
                            </button>
                        </div>

                        {/* Tag Input */}
                        <div className="flex items-center">
                            <label htmlFor="tag" className="flex items-center text-lg font-medium text-gray-700 w-48">
                                <FaTag className="mr-2 text-brand-blue-light" />
                                Tag
                            </label>
                            <input
                                id="tag"
                                type="text"
                                value={tag}
                                onChange={(e) => setTag(e.target.value)}
                                className="flex-grow p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-brand-blue-light transition duration-150 ease-in-out"
                                placeholder="Enter tag" />
                        </div>

                        {/* Save Button */}
                        <div className='flex justify-end'>
                            <button onClick={handleSaveConfiguration} className="bg-brand-blue-light p-2 w-48 text-white rounded-full">
                                Save Configuration
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <ToastContainer position='bottom-right'/>
        </div>
    );
};

export default RTSPConfigurator;
