import React from 'react';

interface AlertCardProps {
  title: string;
  cases: string;
  subText: string;
  icon: React.ReactNode;
  color?: string;
}

const AlertCard: React.FC<AlertCardProps> = ({ title, cases,subText, icon, color = '#888' }) => {
  // Generate a linear gradient background style
  const gradientStyle = {
    backgroundColor: `${color}`,
    backgroundSize: '20px 20px',
    backgroundPosition: '0 0, 10px 10px',
  };

  return (
    <div
      className="rounded-xl w-full sm:w-1/2 lg:w-1/5 p-2 m-2 bg-white text-white shadow-lg"
      style={gradientStyle} // Apply the gradient style here
    >
      <div className="text-2xl text-center font-semibold mb-2">{title}</div>
      <div className="flex flex-row gap-2">
        <div className="flex items-center">
          <div className="text-6xl text-white">{icon}</div>
        </div>
        <div className="flex flex-col justify-center items-center grow">
          <div className="text-5xl font-normal text-white  mb-1 mt-3">{cases}</div>
          <div className="text-lg font-normal text-white mb-3">{subText} </div>
        
        </div>
      </div>
    </div>
  );
};

export default AlertCard;
