import React, { useEffect, useState } from "react"
import { FaStore, FaEnvelope, FaPhone, FaCalendarAlt, FaClock, FaCalendarCheck, FaVideo, FaCamera, FaNetworkWired, FaLaptop, FaEdit, FaAddressCard, FaMobile, FaMobileAlt, FaCalendarDay, FaHourglass, FaHourglassStart } from 'react-icons/fa';

interface StoreDetailsProps {
  storeName: string;
  username: string;
  email: string;
  mobile: string;
  businessDays: boolean[];
  workingHours: string;
}

interface TechnicalDetailsProps {
  dvrModel: string;
  cameraBrand: string;
  internetProvider: string;
  resolution: string;
  cameraFps: number;
}

interface SubscriptionDetailsProps {
  subscriptionEnd: string;
}

const baseURL = process.env.REACT_APP_BASE_URL;



const SettingsPage: React.FC = () => {
  const [settings, setSettings] = useState<any>(null); // Use a more specific type if known

  useEffect(() => {
    const token = localStorage.getItem('token');
    fetch(`${baseURL}/stores/details`, {
      method: 'GET', // or 'POST', 'PUT', 'DELETE', etc., depending on your requirements
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => response.json())
      .then(data => {
        setSettings({
          storeDetails: {
            storeName: data.store_name,
            username: data.username,
            email: data.email,
            mobile: data.mobile,
            businessDays: [
              data.business_days_monday,
              data.business_days_tuesday,
              data.business_days_wednesday,
              data.business_days_thursday,
              data.business_days_friday,
              data.business_days_saturday,
              data.business_days_sunday
            ],
            workingHours: data.working_hours
          },
          technicalDetails: {
            dvrModel: data.dvr_model,
            cameraBrand: data.camera_brand,
            internetProvider: data.internet_provider,
            resolution: data.resolution,
            cameraFps: data.camera_fps
          },
          subscriptionDetails: {
            subscriptionEnd: data.subscription_end
          }
        });
      });
  }, []);

  return settings ? (
    <div className="p-5 bg-slate-100 h-auto">
      <h1 className="text-2xl font-bold text-gray-900 mb-4">Settings</h1>
      <StoreDetails {...settings.storeDetails} />
      <TechnicalDetails {...settings.technicalDetails} />
      <SubscriptionDetails {...settings.subscriptionDetails} />
      <div className='mt-2'>
        <span className="text-md text-gray-600 text-center">
          To modify any of these fields or if you encounter any technical issues, please contact
          <a href="mailto:techsupport@recurrentsoftware.com" className="text-brand-blue-light underline ml-1 mt-4">techsupport@recurrentsoftware.com</a> or reach out to your Customer Relationship Manager.
        </span>
      </div>
    </div>
  ) : (
    <div>Loading...</div>
  );
};

export default SettingsPage;

const StoreDetails: React.FC<StoreDetailsProps> = ({ storeName, email, mobile, workingHours, businessDays }) => {
  const [storeNameLocal, setStoreNameLocal] = useState(storeName);
  const [emailLocal, setEmailLocal] = useState(email);
  const [mobileLocal, setMobileLocal] = useState(mobile);
  const [businessDaysLocal, setBusinessDaysLocal] = useState(businessDays); // Monday to Friday
  const [workingTime, setWorkingTime] = useState({ start: workingHours ? workingHours.split('-')[0] : '09:00', end: workingHours ? workingHours.split('-')[1] : '18:00' });

  const [isEditing, setIsEditing] = useState({
    storeName: false,
    email: false,
    mobile: false
  });

  const handleEditToggle = (field: 'storeName' | 'email' | 'mobile') => {
    setIsEditing(prev => ({ ...prev, [field]: !prev[field] }));
  };

  return (
    <div className=" bg-white p-5 rounded shadow mb-4">
      <h2 className="text-xl text-brand-blue-light font-semibold mb-3 flex items-center"><FaStore className="mr-2" />Store Details</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="flex flex-col">
          <label className="flex items-center text-brand-blue-light">
            <FaAddressCard className="mr-2" />
            Store Name
            {/* <button onClick={() => handleEditToggle('storeName')} className="ml-2">
              <FaEdit className="text-gray-800" />
            </button> */}
          </label>
          {isEditing.storeName ? (
            <input type="text" value={storeNameLocal} onChange={(e) => setStoreNameLocal(e.target.value)} className="input" placeholder="Store Name" />
          ) : (
            <div className="pl-2 py-1">{storeNameLocal}</div>
          )}
        </div>
        <div className="flex flex-col">
          <label className="flex items-center text-brand-blue-light">
            <FaEnvelope className="mr-2" />
            Email
            {/* <button onClick={() => handleEditToggle('email')} className="ml-2">
              <FaEdit className="text-gray-800" />
            </button> */}
          </label>
          {isEditing.email ? (
            <input type="text" value={emailLocal} onChange={(e) => setEmailLocal(e.target.value)} className="input" placeholder="Email" />
          ) : (
            <div className="pl-2 py-1">{emailLocal}</div>
          )}
        </div>
        <div className="flex flex-col">
          <label className="flex items-center text-brand-blue-light">
            <FaMobileAlt className="mr-2 " />
            Mobile
            {/* <button onClick={() => handleEditToggle('mobile')} className="ml-2">
              <FaEdit className="text-gray-800" />
            </button> */}
          </label>
          {isEditing.mobile ? (
            <input type="tel" value={mobileLocal} onChange={(e) => setMobileLocal(e.target.value)} className="input" placeholder="Mobile Number" />
          ) : (
            <div className="pl-2 py-1">{mobileLocal}</div>
          )}
        </div>

        <div>
          <label className="flex items-center text-brand-blue-light">
            <FaCalendarDay className="mr-2 " />
            Business days

          </label>
          <div className="flex space-x-1 mt-1">
            {businessDaysLocal.map((active, index) => (
              <button
                key={index}
                // onClick={() => setBusinessDaysLocal(days => days.map((day, i) => i === index ? !day : day))}
                className={`w-8 h-8 rounded-full flex items-center justify-center text-white font-bold ${active ? 'bg-green-500' : 'bg-red-500'}`}
                title={active ? 'Working' : 'Non-working'}
              >
                {['M', 'T', 'W', 'T', 'F', 'S', 'S'][index]} {/* Display the day initial */}
              </button>
            ))}
          </div>
        </div>
        <div className="col-span-2">
          <label className="flex items-center text-brand-blue-light">
            <FaHourglassStart className="mr-2 " />
            Working hours
          </label>
          <div className="flex space-x-3 mt-1">
            <input type="time" value={workingTime.start}
              // onChange={(e) => setWorkingTime({ ...workingTime, start: e.target.value })}  
              className="input focus:outline-none" />
            <input type="time" value={workingTime.end}
              // onChange={(e) => setWorkingTime({ ...workingTime, end: e.target.value })}
              className="input focus:outline-none" />
          </div>
        </div>
      </div>
    </div>
  );
};

const TechnicalDetails: React.FC<TechnicalDetailsProps> = ({ dvrModel, cameraBrand, cameraFps, internetProvider, resolution }) => {


  return (
    <div className="bg-white p-5 rounded shadow mb-4">
      <h2 className="text-xl text-brand-blue-light font-semibold mb-3 flex items-center"><FaLaptop className="mr-2" />Technical Details</h2>
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col">
          <span className="text-brand-blue-light">DVR Model Number</span>
          <div className="input pl-2 py-1">{dvrModel}</div>
        </div>
        <div className="flex flex-col">
          <span className="text-brand-blue-light">Camera Type</span>
          <div className="input pl-2 py-1">{cameraBrand}</div>
        </div>
        {/* <div className="flex flex-col">
        <span className="text-brand-blue-light">Number of Cameras</span>
          <div className="input pl-2 py-1">{numberOfCameras}</div>
        </div> */}
        <div className="flex flex-col">
          <span className="text-brand-blue-light">Camera Network Provider</span>
          <div className="input pl-2 py-1">{internetProvider}</div>
        </div>
        <div className="flex flex-col">
          <span className="text-brand-blue-light">Camera Resolution</span>
          <div className="input pl-2 py-1">{resolution}</div>
        </div>
        <div className="flex flex-col">
          <span className="text-brand-blue-light">Camera FPS</span>
          <div className="input pl-2 py-1">{cameraFps}</div>
        </div>
      </div>
    </div>
  );
};

const SubscriptionDetails: React.FC<SubscriptionDetailsProps> = ({ subscriptionEnd }) => {

  return (
    <div className="bg-white p-5 rounded shadow">
      <h2 className="text-xl text-brand-blue-light font-semibold mb-3 flex items-center"><FaCalendarCheck className="mr-2" />Subscription Details</h2>
      <div className="flex flex-col">
        <span className="text-brand-blue-light">Plan Valid Until</span>
        <div className="input pl-2 py-1">{subscriptionEnd}</div>
      </div>
    </div>
  );
};

