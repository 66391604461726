import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { FiHome, FiClipboard, FiCalendar, FiSettings, FiLifeBuoy } from 'react-icons/fi';
import {Sidebar, SidebarItem} from './components/Sidebar';
import TopBar from './components/TopBar';
import Home from './pages/Home';
import Alerts from './pages/Alerts';
import Inputs from './pages/Inputs';
import Settings from './pages/Settings';
import Help from './pages/Help';
import LoginPage from './pages/LoginPage';
import Main from './pages/main';
import SetPasswordPage from './pages/SetPassword';
import ResetPasswordPage from './pages/ResetPassword';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);  // New state to handle loading

  useEffect(() => {
    const token = localStorage.getItem('token');
    // console.log('Token:', token);
    setIsAuthenticated(!!token);  // Set authentication based on token presence
    setLoading(false);  // Set loading to false after checking the token
  }, []);

  if (loading) {
    return <div>Loading...</div>;  // Or some other loading indicator
  }

  return (
    <Router>
      <Routes>
      <Route path="/setPassword" element = {<SetPasswordPage />} />
      <Route path="/resetPassword" element = {<ResetPasswordPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="*" element={<Main />} />
      </Routes>
    
    </Router>
  );
}

export default App;
