import React, { useEffect, useState } from "react"
import AlertCard from '../components/AlertCard';
import { FaCamera, FaCameraRetro, FaHandsWash, FaHeadSideMask, FaObjectGroup, FaStore, FaStreetView } from 'react-icons/fa';
import Card from "../components/Card";
import CalendarChart from "../components/CalendarChart";
import StoreAnalysis from "../components/StoreAnalysis";
import TheftsBarGraph from "../components/TheftsBarGraph";
import { useNavigate } from 'react-router-dom';


function Home() {
  const [data, setData] = useState({
    blocking: 0,
    calendar: { start_date: "01-01-2024", thefts: [] },
    cameras: 0,
    stores: 0,
    tampering: 0,
    thefts: {}
  });
  const navigate = useNavigate(); 

  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${baseURL}/stats`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (response.status === 401 || response.status === 403) {
          // If unauthorized or forbidden, clear the token and redirect to login
          localStorage.removeItem('token');
          navigate('/login');
          return; // Stop further execution since there's an auth issue
        }

        const json = await response.json();
        if (response.ok) {
          setData(json.data);
        } else {
          throw new Error('Failed to fetch data');
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };
    fetchData();
  }, [navigate]);


  return (
    <div className="flex flex-col gap-4 items-center bg-slate-50">
      <div className="flex flex-wrap w-full justify-center items-center my-4">
        <AlertCard
          title="Stores"
          cases={data.stores.toString()}
          subText="Deployed"
          icon={<FaStore style={{ fontSize: '50px' }} />}
          color="#60B9CB"
        />
        <AlertCard
          title="Cameras"
          cases={data.cameras.toString()}
          subText="Installed"
          icon={<FaCamera style={{ fontSize: '50px' }} />}
          color="#168AAF"
        />
        <AlertCard
          title="Camera Tampering"
          cases={data.tampering.toString()}
          subText="Cases"
          icon={<FaCameraRetro style={{ fontSize: '50px' }} />}
          color="#8A6088"
        />
        <AlertCard
          title="Obstructions"
          cases={data.blocking.toString()}
          subText="cases"
          icon={<FaObjectGroup style={{ fontSize: '50px' }} />}
          color="#D98096"
        />

      </div>
      {/* <div className='grid grid-cols-12 gap-4 max-w-full p-4 font-semibold'>
        <div className="col-span-12">
          <Card title="Theft trend">
            <TheftsBarGraph thefts={data.thefts} />
          </Card>
        </div>
        <div className="col-span-12">
          <Card title="Theft cases reported">
            <CalendarChart startDate={data.calendar.start_date} thefts={data.calendar.thefts} />
          </Card>
        </div>
      </div> */}
    </div>
  );
}

export default Home;