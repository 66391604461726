import React, { useState } from 'react';
import CamerasTab from '../components/CamerasTab'; // Import the CamerasTab component
import VideosTab from '../components/VideosTab';
// import VideoTab from './VideoTab';

interface TabProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
}

const Tab: React.FC<TabProps> = ({ label, isActive, onClick }) => (
  <button
    className={`px-4 py-2 ${isActive ? 'text-brand-blue-light border-b-2 border-brand-blue-light' : 'text-gray-500'} hover:text-blue-500 transition duration-150`}
    onClick={onClick}
  >
    {label}
  </button>
);


const InputPage = () => {
  const [activeTab, setActiveTab] = useState('video');

  // return (
  //   <div>
  //     <div className="flex space-x-4  pb-2 justify-center font-semibold text-xl bg-gray-100">
  //       <Tab label="Video" isActive={activeTab === 'video'} onClick={() => setActiveTab('video')} />
  //       <Tab label="Cameras" isActive={activeTab === 'cameras'} onClick={() => setActiveTab('cameras')} />
  //     </div>
  //     {activeTab === 'cameras' ? <CamerasTab /> : <VideosTab />}
  //   </div>
  // );
  return (
    <CamerasTab /> 
  );
};

export default InputPage;