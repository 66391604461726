import React, { useState } from 'react';
import { FiUser, FiBell, FiInfo, FiX, FiMenu } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const TopBar = () => {
    const [showLogout, setShowLogout] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token'); // Remove the stored token
        navigate('/login'); // Navigate to login page
    };
    
    return (
        <div className="bg-white shadow w-full h-16 flex justify-between items-center px-4 sm:px-6">
            <button
                        onClick={() => setSidebarOpen(!sidebarOpen)}
                        className="text-gray-600 p-2 rounded-md lg:hidden"
                    >
                        {sidebarOpen ? <FiX size={24} /> : <FiMenu size={24} />}
                    </button>
            <div className="text-lg sm:text-xl flex flex-row font-semibold items-center justify-center">
                <p className='text-gray-600 text-center'>Neurawatch Anti-Theft Platform</p>
                {/* Uncomment the next line if the icon should be shown */}
                {/* <FiInfo size={15} className='text-gray-600 ml-3' /> */}
            </div>
            <div className="flex items-center">
                <div className='hidden sm:block sm:mr-5'>
                    <input type="text" placeholder="Search..." className="p-1 border border-gray-300 rounded-lg" />
                </div>
                <div className="relative mr-3 sm:mr-4 cursor-pointer">
                    <FiBell style={{ fontSize: '30px' }}/>
                    {/* Uncomment the next lines if notification count should be shown */}
                    {/* <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">5</span> */}
                </div>
                <div className="relative ml-3 sm:ml-4 cursor-pointer">
                    <div onClick={() => setShowLogout(!showLogout)}>
                        <FiUser style={{ fontSize: '30px' }} />
                    </div>
                    {showLogout && (
                        <button onClick={handleLogout} className="absolute top-full right-0 mt-2 bg-white border border-gray-300 shadow px-4 py-2 rounded-lg text-sm">
                            Logout
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TopBar;
