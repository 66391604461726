import React, { useEffect, useState } from 'react';
import { redirect, redirectDocument, useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory
import { FaUserAlt, FaLock } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';


const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const navigate = useNavigate(); // Create a navigate function using useNavigate


  // useEffect(() => {
  //   console.log(loggedIn);
  //   if (loggedIn) {
  //     navigate('/home');  // Navigate to home if logged in
  //   } else {
  //     // navigate('/login'); // This line can cause unnecessary navigation
  //     // Consider removing it since this component is already the login page.
  //     // If you want to ensure users are not on this page when logged in,
  //     // handle this logic at a higher level (such as routing guards).
  //   }
  // }, [loggedIn, navigate]);
  const handleForgotPasswordBtn = async (event: React.FormEvent) => {
    event.preventDefault(); 
    setIsResetPassword(true);
    setUsername('');  
  }

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault(); // Prevent the default form submission behavior
    const baseUrl = process.env.REACT_APP_BASE_URL
    setIsResetPassword(false);
    try {
      const response = await fetch(`${baseUrl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('token', data.token); // Store token in localStorage
        setLoggedIn(true);
        // console.log('navigate');
        navigate('/home');
      } else {
        console.error('Login failed:', response.status);
        toast.error('Login failed, please check your credentials.');
      }
    } catch (error) {
      console.error('Login error:', error);
      toast.error('An error occurred, please try again later.');
    }
  };

  const handleForgotPassword = async (event: React.FormEvent) => {
    event.preventDefault(); // Prevent the default form submission behavior
    const baseUrl = process.env.REACT_APP_BASE_URL

    try {
      const response = await fetch(`${baseUrl}/stores/reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username }),
      });
      if (response.ok) {
        toast.success('Reset password link sent successfully.');
      } else {
        toast.error('Reset failed !! Please try again');
      }
    } catch (error) {
      toast.error('Reset failed !! Please try again');
    }
  };

  return (
    <div className="min-h-screen flex flex-col" style={{
      backgroundImage: 'url(/hero.webp)',
      backgroundSize: 'cover',
      backgroundPosition: 'left',
      backgroundAttachment: 'fixed' // Ensure background image is fixed during scroll
    }}>
      <div className="h-screen bg-gray-400 bg-opacity-80 flex justify-center items-center"> {/* Overlay and adjustment */}
      {!isResetPassword?(<div className="flex flex-col gap-4 items-center h-auto w-full max-w-md px-4 bg-brand-blue-light shadow-lg py-6 rounded-2xl">
         <div className="mb-4 text-white text-4xl">Login</div>
          <form onSubmit={handleLogin} className="rounded flex flex-col justify-center items-center"> {/* Form layout */}
            <div className="mb-4">
              <label htmlFor="username" className="block text-sm font-medium text-white flex items-center">
                <FaUserAlt className="mr-2" />Username
              </label>
              <input
                id="username"
                type="text"
                value={username}
                onChange={e => setUsername(e.target.value)}
                className="mt-1 block w-96 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block text-sm font-medium text-white flex items-center">
                <FaLock className="mr-2" />Password
              </label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                className="mt-1 block w-96 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>
            <div className="flex w-96 justify-between items-center mb-4">
              <label className="flex items-center text-sm text-white">
                <input type="checkbox" className="form-checkbox h-4 w-4 text-brand-blue-dark focus:text-brand-blue-light border-gray-300 rounded" />
                <span className="ml-2">Remember Me</span>
              </label>
              <button type="submit" onClick={handleForgotPasswordBtn} className="text-sm text-white hover:text-gray-800">Forgot Password?</button>
            </div>
            <button type="submit" className="w-72 bg-[#8A6088]  text-white font-bold py-2 px-4 rounded-full shadow-lg">
              Login
            </button>
          </form>
        </div>):(
          <div className="flex flex-col gap-4 items-center h-auto w-full max-w-md px-4 bg-brand-blue-light shadow-lg py-6 rounded-2xl">
            <div className="mb-4 text-white text-4xl">ForgotPassword</div>
            <form onSubmit={handleForgotPassword} className="rounded flex flex-col justify-center items-center"> {/* Form layout */}
              <div className="mb-4">
                <label htmlFor="username" className="block text-sm font-medium text-white flex items-center">
                  <FaUserAlt className="mr-2" />Username
                </label>
                <input
                  id="username"
                  type="text"
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                  className="mt-1 block w-96 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  required
                />
              </div>

              <button type="submit" className="w-72 bg-[#8A6088]  text-white font-bold py-2 px-4 rounded-full shadow-lg">
                Reset Password
              </button>
            </form>
          </div>)}

        <ToastContainer position='bottom-right' />
      </div>
    </div>
  );
}

export default LoginPage;
