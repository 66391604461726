import React from 'react';

interface ModalProps {
  children: React.ReactNode; // Content to display inside the modal
  onClose: () => void; // Function to call when closing the modal
  onAgree?: () => void; // Optional function to call when agreeing (if applicable)
}

const Modal: React.FC<ModalProps> = ({ children, onClose, onAgree }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white rounded-lg p-2 w-11/12">
        {/* <div className="flex justify-between items-center mb-4">
          <button onClick={onClose} className="text-xl font-bold">&times;</button>
        </div> */}
        {children}
        <div className="mt-4 flex justify-end space-x-3">
          {onAgree && (
            <button onClick={onAgree} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              Agree
            </button>
          )}
          <button onClick={onClose} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
