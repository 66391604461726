import React, { useEffect, useState } from 'react';
import { FaTags, FaCamera, FaCheckCircle, FaTimesCircle, FaCircle, FaTrash } from 'react-icons/fa';

import { FaSort, FaFilter, FaPlus } from 'react-icons/fa';
import Modal from './Modal';
import RTSPConfigurator from './RTSPConfigurator';
import { ToastContainer, toast } from 'react-toastify';


export interface Camera {
  id: number;
  rtsp_url: string;
  tag: string;
  status: 'connected' | 'disconnected';
}

const CamerasTab = () => {
  const [showModal, setShowModal] = useState(false);
  const [reload, setReload] =useState(false)
  const [cameras, setCameras] = useState<Camera[]>([]);

  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetch(`${baseURL}/camera`, {
      method: 'GET', // or 'POST', 'PUT', 'DELETE', etc., depending on your requirements
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
      }})
      .then(response => response.json())
      .then(data => {
        setCameras(data);
      });
  }, [reload]);
  // const cameras: Camera[] = [
  //   { id: 1, channel: 'Channel 1', rtsp_url: 'rtsp://example.com/1', tag: 'Entrance', status: 'connected' },
  //   { id: 2, channel: 'Channel 2', rtsp_url: 'rtsp://example.com/2', tag: 'Lobby', status: 'disconnected' },
  //   // Add more initial data if needed
  // ]
  const totalCameras = cameras.length;
  const connectedCameras = cameras.filter(camera => camera.status === 'connected').length;
  const disconnectedCameras = totalCameras - connectedCameras;
  const uniqueTags = new Set(cameras.map(camera => camera.tag));
  const totalTags = uniqueTags.size;

  const connectedPercentage = ((connectedCameras / totalCameras) * 100).toFixed(2);
  const disconnectedPercentage = ((disconnectedCameras / totalCameras) * 100).toFixed(2);

  const generateColorFromString = (str: string): string => {
    // Create a hash from the string
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Convert the hash to a 6-digit hexadecimal color with adjustments for brightness
    let color = '#';
    for (let i = 0; i < 3; i++) {
      // Extract parts of the hash for each color component
      const value = (hash >> (i * 8)) & 0xFF;
      // Adjust each color component to be within the range 128 to 255 to ensure brightness
      const brightValue = Math.floor((value % 128) + 128).toString(16);
      color += ('00' + brightValue).substr(-2);
    }

    return color;
  };

  const addCamera = () => {
    setShowModal(true)
    // setCameras([...cameras, newCamera]);
  };

  const deleteCamera = async (cameraId: String) => {
    try {
      const response = await fetch(`${baseURL}/camera/${cameraId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (response.ok) {
        toast.success("Successfully deleted camera")
        setReload(true)
        console.log("Camera deleted successfully!");
        // Optionally update the state to remove the camera from the list
      } else {
        throw new Error('Failed to delete the camera');
      }
    } catch (error) {
      toast.error("Error deleting camera")
      console.error('Error deleting camera:', error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-10">
        <div className="p-4 bg-blue-500 text-white border rounded-md shadow flex items-center">
          <FaTags className="text-4xl mr-4" />
          <div>
            <h3 className="text-lg font-semibold">Total Tags</h3>
            <p className="text-2xl">{totalTags}</p>
          </div>
        </div>
        <div className="p-4 bg-green-500 text-white border rounded-md shadow flex items-center">
          <FaCamera className="text-4xl mr-4" />
          <div>
            <h3 className="text-lg font-semibold">Total Cameras</h3>
            <p className="text-2xl">{totalCameras}</p>
          </div>
        </div>
        <div className="p-4 bg-indigo-500 text-white border rounded-md shadow flex items-center">
          <FaCheckCircle className="text-4xl mr-4" />
          <div>
            <h3 className="text-lg font-semibold">Connected Cameras</h3>
            <p className="text-2xl">{connectedCameras}</p>
            <p>{connectedPercentage}%</p>
          </div>
        </div>
        <div className="p-4 bg-red-500 text-white border rounded-md shadow flex items-center">
          <FaTimesCircle className="text-4xl mr-4" />
          <div>
            <h3 className="text-lg font-semibold">Disconnected Cameras</h3>
            <p className="text-2xl">{disconnectedCameras}</p>
            <p>{disconnectedPercentage}%</p>
          </div>
        </div>
      </div>

      <div className="">
        <div className="flex justify-between items-center mb-4">
          <div className="flex space-x-2">
            <button className="flex items-center px-2 py-1 border rounded-md hover:bg-gray-200">
              <FaSort className="mr-1" /> Sort
            </button>
            <button className="flex items-center px-2 py-1 border rounded-md hover:bg-gray-200">
              <FaFilter className="mr-1" /> Filter
            </button>
          </div>
          <button className="flex items-center px-4 py-2 bg-brand-blue-light text-white rounded-xl hover:bg-brand-blue-dark" onClick={addCamera}>
            <FaPlus className="mr-1" /> Add Camera
          </button>
        </div>
        <table className="min-w-full bg-white border">
          <thead>
            <tr className="w-full bg-gray-100 text-left text-gray-500">
              <th className="py-2 px-4 border-b">Camera Channel</th>
              <th className="py-2 px-4 border-b">RTSP URL</th>
              <th className="py-2 px-4 border-b">Tag</th>
              <th className="py-2 px-4 border-b">Status</th>
              <th className="py-2 px-4 border-b text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {cameras.map(camera => (
              <tr key={camera.id} className="border-b hover:bg-gray-50 text-gray-800">
                <td className="py-2 px-4">{camera.rtsp_url?camera.rtsp_url.split('/').pop() :'Channel'}</td>
                <td className="py-2 px-4">{'rtsp://'+camera.rtsp_url.split('@')[1]}</td>
                <td className="py-2 px-4 " >
                  <div className="py-1 px-4 text-gray-800 rounded-full w-fit text-sm" style={{ backgroundColor: generateColorFromString(camera.tag) }}>{camera.tag}</div>

                </td>
                <td className="py-2 px-4 flex items-center">
                  <FaCircle className={`mr-2 ${camera.status === 'connected' ? 'text-green-500' : 'text-red-500'}`} />
                  <span className={`${camera.status === 'connected' ? 'text-green-500' : 'text-red-500'}`}>{camera.status}</span>
                </td>
                <td className="py-2 px-4 text-center">
            <button onClick={() => deleteCamera(camera.id.toString())} className="text-red-500 hover:text-red-700">
              <FaTrash />
            </button>
          </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showModal && (<AddCameraModal onClose={() => setShowModal(false)}>
        <RTSPConfigurator onClose={() => setShowModal(false)} onSuccess={()=> setReload(true)} />
      </AddCameraModal>)}
      <ToastContainer position='bottom-right' />
    </div>
  );
};

export default CamerasTab;

interface AddCameraModalProps {
  children: React.ReactNode; // Content to display inside the modal
  onClose: () => void; // Function to call when closing the modal
}

const AddCameraModal: React.FC<AddCameraModalProps> = ({ children, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-slate-100 rounded-lg p-4 w-1/2 flex flex-col items-center">

        {children}

        <button onClick={onClose} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
          Close
        </button>
      </div>
    </div>
  );
};
